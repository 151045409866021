import React from 'react';
import Gallery from 'react-grid-gallery';

const GalleryGrid = props => {
	return (

		<div style={{
			display: "block",
			// minHeight: "1px",
			width: "100%",
			border: "0px solid #ddd",
			overflow: "auto",
			textAlign: "center",
			background: "white"
		}}>

			<Gallery
				enableImageSelection={false}
				images={props.images}/>
		</div>
	)
}

export default GalleryGrid;