import React from 'react';
import FooterContainer from './components/footer'

const Footer = props => {
	return (
		<div>
			<FooterContainer />
		</div>
	)
}

export default Footer;
