import React from 'react';
import './icons-grid.css';

const IconsGridSection = props => {
	let items = [
		{
			title : "functional training",
			description : "Squeeze 100% benefit from the universal training: speed, strength, endurance",
			icon : "fas fa-dumbbell"
		},
		{
			title : "functional training",
			description : "Squeeze 100% benefit from the universal training: speed, strength, endurance",
			icon : "fas fa-dumbbell"
		},
		{
			title : "functional training",
			description : "Squeeze 100% benefit from the universal training: speed, strength, endurance",
			icon : "fas fa-dumbbell"
		}//,
		// {
		// 	title : "functional training",
		// 	description : "Squeeze 100% benefit from the universal training: speed, strength, endurance",
		// 	icon : "fas fa-dumbbell"
		// },
		// {
		// 	title : "functional training",
		// 	description : "Squeeze 100% benefit from the universal training: speed, strength, endurance",
		// 	icon : "fas fa-dumbbell"
		// },
		// {
		// 	title : "functional training",
		// 	description : "Squeeze 100% benefit from the universal training: speed, strength, endurance",
		// 	icon : "fas fa-dumbbell"
		// }
	]
	return (
		<div className={"icons-grid"}>
			{
				items.map((item, i) => {
					return <GridItem key = {i} {...item} />
				})
			}
		</div>
	)
}

export default IconsGridSection;

//
const GridItem = props => {
	let { icon, title, description } = props;
	return (
		<div
			className={"item"}
		>
			<i className={icon}></i>
			<h2>{title}</h2>
			<p>{description}</p>
		</div>
	)
}