import React, { useState } from 'react';
import Section from "./components/section";
import MapSection from "./sections/mapSection";
import MainSection from "./sections/mainSection";
import FastContactSection from "./sections/fastContactSection";
import AboutSection from "./sections/aboutSection";
import SplittedClassesSection from "./sections/splittedClassesSection";
import OurClassesSection from "./sections/ourClassesSection";
import IconsGridSection from "./sections/iconsGridSection";
import TrainingsSection from "./sections/trainingsSection";
import Fab from "@material-ui/core/Fab/Fab";
import {makeStyles} from "@material-ui/core";
import {fade} from "@material-ui/core/styles";
import ImagesGridSection from "./sections/imagesGridSection";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
	fab : {
		backgroundColor : '#d7272a',
		'&:hover' : {
			backgroundColor : '#3a3a3a'
		}
	}
}));

const HomePage = props => {
	let { translate } = props;

	const classes = useStyles();
	let [showFab, setShowFab] = useState(false);

	let handleScroll = e => {
		let _showFab = window.scrollY > 100;
		setShowFab(_showFab)
	}

	window.addEventListener('scroll', handleScroll, true);

	return (
		<div style={{width: '100%'}} >
			<Section
				id = "home"
				style = {{marginTop: 0, marginBottom : 0, width: '100%'}}
				translate = {translate}
				content = {(props) => {return <MainSection {...props} />}}
			/>

			{/* <Section
				id = "classes"
				style = {{marginTop: 0, width: '100%'}}
				content = {(props) => {return <SplittedClassesSection {...props} />}}
			/> */}

			<Section
				id = "about"
				style = {{width: '100%'}}
				translate = {translate}
				content = {(props) => {return <AboutSection {...props} />}}
			/>

			{/* { <Section
				id = "trainings"
				style = {{marginTop: 0, width: '100%'}}
				content = {(props) => {return <TrainingsSection {...props} />}}
			/> } */}

			{/* <Section
				id = "iconsGrid"
				content = {(props) => {return <IconsGridSection {...props} />}}
			/> */}

			<Section
				id = "ourClasses"
				translate={translate}
				content = {(props) => {return <OurClassesSection {...props} />}}
			/>

			<Section
				id = "imagesGrid"
				style = {{
					width: '100%',
				}}
				content = {(props) => {return <ImagesGridSection {...props} />}}
			/>

			{/* <Section
				id = "fastContact"
				style = {{
					width: '100%',
					backgroundColor : '#d7272a'
				}}
				content = {(props) => {return <FastContactSection {...props} />}}
			/> */}

			<Section
				id = "map"
				translate={translate}
				content = {(props) => {return <MapSection {...props} />}}
			/>

			<div style = {{
				position: 'fixed',
				right: 50,
				bottom: 50,
				display : showFab ? 'block' : 'none'
			}}>
				<Fab
					onClick = {() => {
						window.location = '#'
					}}
					className = {"desktop " + classes.fab}
					style ={{
						color: '#fff',
						fontSize: 'larger',
					}}>
					<i className="fas fa-arrow-up"></i>
				</Fab>
			</div>
		</div>
	)
}


HomePage.propTypes = {
	translate : PropTypes.func
}

export default HomePage;