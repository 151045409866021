import React from 'react';
import Viewer from "./components/viewer";

import './splitted-class-section.css'

const SplittedClassesSection = prpos => {
	return (
		<div
			className={"splitted-class-section"}
		>

			<Viewer
				title = {"Sport Karate"}
				description = {"We have a philosophy when it comes to fitness. There are no shortcuts on the road to success. We believe in teaching people the importance of longevity. There is no such thing as an overnight success."}
				backgroundImage = {""}
			/>

			<Viewer
				title = {"Traditional Karate"}
				description = {"We have a philosophy when it comes to fitness. There are no shortcuts on the road to success. We believe in teaching people the importance of longevity. There is no such thing as an overnight success."}
				backgroundImage = {""}
			/>

		</div>
	)
}

export default SplittedClassesSection;


