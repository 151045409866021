import React from 'react';
import SearchAppBar from "./components/appbar";
// import Appbar from "./components/newAppbar";

const Header = props => {
	let { translate } = props;

	// generate top nav items based on given language.
	let navItems = translate('TopNavItems', { returnObjects: true})
		.map(i => {
			let item = {
				title : i["1"],
				href : i["sectionId"]
			}
			return item;
		});


	return (
		<div>
			<SearchAppBar items = {navItems} />
		</div>
	)
}

export default Header;
