import React from 'react';
import img from './images/map.jpg';
import './map-section.css'

import './form.css'

const MapSection = props => {
	let { translate } = props;
	return (
		<div className = "map-section">

			<div className={"left"}>
				<FormContainer translate = {translate} />
			</div>

			<div className = "right">
				<MapContainer  />
			</div>
		</div>
	)
}

export default MapSection;

//
const MapContainer = props => {

	return (
		<div className="mapouter">
			<div class="gmap_canvas">
				<iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=%D7%9E%D7%AA%D7%A0%22%D7%A1%20%D7%A0%D7%95%D7%95%D7%94%20%D7%99%D7%A9%D7%A8%D7%90%D7%9C&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
			</div>
		</div>
	)
}

const FormContainer = props => {
	let { translate } = props;

	let onSubmit = e => {
		e.preventDefault();

		alert("Implement submit form");
	}

	return (
		<div className = "get-in-touch-form-container" >
			<h2>{translate("MapSection.ClassScheduleTitle")}</h2>
			<h4>{translate("MapSection.ClassScheduleClubAddress")}</h4>
			<h4>{translate("MapSection.ClassScheduleSubTitle")}</h4>
			<h4>{translate("MapSection.WeekdayClassTimes")}</h4>
			<h4>{translate("MapSection.FridayClassTimes")}</h4>
			<h4>{translate("MapSection.SaturdayClassTimes")}</h4>
			<br />
			<h2>{translate("MapSection.ContactUsTitle")}</h2>
			<h4>{translate("MapSection.1stContactPersonDetails")}</h4>
			<h4>{translate("MapSection.2stContactPersonDetails")}</h4>
			<h4>{translate("MapSection.3rdContactPersonDetails")}</h4>
		</div>
	)
}