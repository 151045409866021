import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AppbarLink from "./components/appbarLink";

import './appbar.css';
import MobileMenuDrawer from "./components/drawer";
import atemiLogo from './resources/images/atemi_logo.png';

const appbarLinks = [
	<AppbarLink title={"ראשי"} href={"#"}/>,
	<AppbarLink title={"אודות"} href={"#about"}/>,
	<AppbarLink title={"אימונים"} href={"#ourClasses"}/>,
	<AppbarLink title={"יצירת קשר"} href={"#map"}/>,
]

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		textAlign: 'left',
		margin: '0 auto',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		// display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
		width: '50%'
	},
	toolbar : {
		backgroundColor : '#fff',
		minHeight: 70,
		color: '#000',
		boxShadow : 'none'
	},
	links : {
	},
	appbar : {
		boxShadow: 'none'
	},

	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 120,
			'&:focus': {
				width: 200,
			},
		},
	},
	link : {
		color: 'inherit',
		textDecoration: 'inherit',
		'&:hover' : {
			color: 'inherit',
			textDecoration: 'inherit'
		}
	}
}));

export default function SearchAppBar(props) {
	let { items } = props;

	const classes = useStyles();
	let [openDrawer, setOpenDrawer] = useState(false);


	const toggleDrawer = (open) => event => {
		document.getElementsByClassName('MuiButtonBase-root')[0].blur();
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setOpenDrawer(open);
	};

	return (
		<div className={classes.root + " appbar"}>
			<AppBar position="static" className={classes.appbar}>
				<Toolbar className={classes.toolbar}>


					<Typography className={"desktop " + classes.title} variant="h6" noWrap>
						<div style = {{display: 'flex'}}>
						<div style={{textAlign: 'center', paddingTop: 10}}>
							<img style= {{height: 60, width: 60}} src={atemiLogo} />
								<div>
								<a href="#" className = {classes.link} style = {{fontSize: 'small'}}>Atemi <span style = {{fontSize: 'small', fontWeight: 200}}>Karate club</span></a>
								</div>
								{/* <a href="#" className = {classes.link} style = {{fontSize: 'medium', fontWeight: 200}}>Karate club</a> */}
							</div>
						</div>
					</Typography>


					<div className="desktop links">
						{
							items.map((item, idx) => {
								return 	<AppbarLink title={item.title} href={item.href} key={idx}/>
							})
						}
					</div>

					<div className="mobile drawer-container">
						<IconButton
							onClick={toggleDrawer(true)}
							edge="start"
							className={classes.menuButton}
							color="inherit"
							aria-label="open drawer"
						>
							<MenuIcon/>
						</IconButton>

						<MobileMenuDrawer
							open={openDrawer}
							onClose={toggleDrawer}
							menu = {
								() => {
									return (
										<div>
											{
												appbarLinks.map((link, i) => {
													return link;
												})
											}
										</div>
									)
								}
							}
						/>
					</div>

				</Toolbar>
			</AppBar>
		</div>
	);
}