import React from 'react';
import './trainings-section.css';

const TrainingsSection = props => {
	return (
		<div className = "trainings-section">
			<TrainingSection />
			<TrainingSection />
			<TrainingSection />
		</div>
	)
}

export default TrainingsSection;

//

const TrainingSection = props => {
	let imgURL = 'https://template81566.motopreview.com/mt-demo/81500/81566/mt-content/uploads/2019/05/mt-1821-img03.jpg';

	return (
		<div
			style = {{
				backgroundImage : `url(${imgURL})`
			}}
			className={"training-section"}>

			<div className={"info-container"}>
				<h1>Kumite</h1>
				<div className={"description-container"}>
					<h2 className = "description" >Description</h2>
				</div>

				<div className = "button">
					Book now
				</div>
			</div>
		</div>
	)
}