import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18next';

ReactDOM.render(
	<Suspense fallback={(
		<div className={"home loading"}>
			<i className="fas fa-spinner fa-spin"></i>
		</div>
	)}>
		<App />
	</Suspense>,

	document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
