import React from 'react';
import GalleryGrid from "./components/galleryGrid";
import img_1 from './resources/images/1.jpeg';
import img_2 from './resources/images/2.jpeg';
import img_3 from './resources/images/3.jpeg';
import img_4 from './resources/images/4.jpeg';
import img_5 from './resources/images/5.jpeg';
import img_6 from './resources/images/6.jpeg';

import './image-gallery.css';

const ImagesGridSection = props => {
	let images = [
		{
			src: img_1,
			thumbnail: img_1,
			// thumbnailWidth: 639,
			// thumbnailHeight: 381,
			caption: "",
		},
		{
			src: img_2,
			thumbnail: img_2,
			caption: "",
			// thumbnailWidth: 640,
			// thumbnailHeight: 616,

		},
		{
			src: img_3,
			thumbnail: img_3,
			caption: "",
			// thumbnailWidth: 634,
			// thumbnailHeight: 540,
		},
		{
			src: img_4,
			thumbnail: img_4,
			caption: "",
			// thumbnailWidth: 634,
			// thumbnailHeight: 457,
		},
		{
			src: img_5,
			thumbnail: img_5,
			caption: "",
			// thumbnailWidth: 500,
			// thumbnailHeight: 200,
		},
		{
			src: img_6,
			thumbnail: img_6,
			caption: "",
			// thumbnailWidth: 500,
			// thumbnailHeight: 200,
		},
	]
	return (
		<div>
			<div className={"desktop"}>
				<GalleryGrid images = {images} />
			</div>

			<div className={"mobile"}>
				<img src={images[2].src} />
			</div>


		</div>
	)
}

export default ImagesGridSection;