import React from 'react';
import {makeStyles} from "@material-ui/core";
import {fade} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
	root: {
		textAlign: 'right',
		width: 'auto',
		color: '#000',
		marginRight: 20

},
	href : {
		textDecoration: 'none',
		color: 'inherit',
		'&:hover': {
			color: fade('#d7272a', 1),
			textDecoration: 'none'
		},
		fontWeight: 400,
		fontSize: 18,
		position: 'relative',
		display: 'block',
		textAlign: 'left',
		transition: '.3s',
		fontStyle: 'normal',
	}
}));


const AppbarLink = ({ title, href }) => {
	const classes = useStyles();

	return (
		<div className = {classes.root} className = "link" >
			<a className = {classes.href} href = {href} >{title}</a>
		</div>
	)
}

export default AppbarLink;