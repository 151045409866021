import React from "react";
import './viewer.css';

const Viewer = props => {
	let {title, description, backgorundImage } = props;

	return (
		<div
			className = "split viewer-container"
			style = {{
			border: '1px solid #000',
			textAlign : 'center',
			backgroundColor : '#000',
			padding: 50
		}}>
			<div className = "viewer" >
				<h1>{title}</h1>
				<p>{description}</p>
				<div>
					<div
						style = {{
							margin: 'auto',
						}}
						className={"button"}>
						VIEW MORE
					</div>
				</div>
			</div>
		</div>
	)
}


export default Viewer;