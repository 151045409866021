import React from 'react';
import PropTypes from 'prop-types';
import MainSection from "../../sections/mainSection";

const Section = ({id, style, content, translate}) => {
	let Content = content;
	return (
		<div
			id = {id}
			style = {style}
			className={"section"}>
			<Content
				translate = {translate}
			/>
		</div>
	)
}

Section.propTypes = {
	id : PropTypes.string,
	content : PropTypes.object,
	translate : PropTypes.func
}

export default Section;