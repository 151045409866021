import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomePage from "./pages/home";
import Header from "./components/header";
import Footer from "./components/footer";
import { useTranslation } from 'react-i18next';

function App() {
	const { t } = useTranslation();

	// TODO: Init these values based on detected language. (Currently hardcoded to heb).
	let _direction = "rtl";
	let _textAlign = "right";

	return (
		<div className="App" style = {{
			direction : _direction,
			textAlign : _textAlign
		}}>
			<Header translate = {t} />
				<HomePage translate = {t} />
			<Footer />
		</div>
	);
}

export default App;
