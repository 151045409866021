import React from 'react';
import './main-section.css'
import PropTypes from "prop-types";
import Button from "../../components/button";

const MainSection = props => {
	let { translate } = props;

	let imgSrc = 'https://accreditation.qtixx.com/wkfranking/images/slide2.jpg';

		// 'https://www.wkf.net/imagenes/galeria/2014-finland-tampere/49th-european-senior-championships-158.jpg';
		// 'https://media.wired.com/photos/5a5945a5e5ee3c6a50ce5287/master/pass/britishforest-696483208.jpg';

	return (
		<div className = "with-background-image">
			<div style={{color: '#fff', paddingTop: 150, marginTop: 0}} className={"section"}>
				<h1 className={"title"} style = {{fontSize : 64, marginBottom: -11}}>{translate("MainSection.Title.1")}</h1>
				<h1 className={"title"} style = {{fontSize : 64, marginBottom: 20}}>{translate("MainSection.Title.2")}</h1>

				<h2 style = {{fontSize : 20}}>{translate("MainSection.SubTitle.1")}</h2>
				<h2 style = {{fontSize : 20}}>{translate("MainSection.SubTitle.2")}</h2>

				<Button
					style = {{marginTop: 35}}
					href={"#map"}
					variant = {"contained"}
					title = {translate("MainSection.CallToActionButton")} />
			</div>
		</div>
	)
}

export default MainSection;