import React from 'react';
import './button.css';

const Button = props => {
	let { variant, color, title, style, href, onClick } = props;
	return (
		<div style = {style}>
			<a
				href = {href}
				onClick = {onClick}
				className={`button ${variant}`}>
				{title}
			</a>
		</div>
	)
}


export default Button;
