import React from 'react';
import './fast-contact-section.css';


const FastContactSection = props => {
	return (
		<div className={"section fast-contact-section"}>
			<div>
				<h1>GOT A QUASTION? WE WOULD BE HAPPY TO HELP!</h1>
				<h3>Pellentesque sed nibh blandit, ultricies eros at, dignissim tellus ac eleifend velit</h3>
			</div>

			<div style = {{
				margin: 'auto'
			}}>
				<div className = "button">
					CONTACT US
				</div>
			</div>
		</div>
	)
}

export default FastContactSection;