import React from 'react';
import Drawer from "@material-ui/core/Drawer";

const MobileMenuDrawer = props => {
	let { open, menu } = props;


	return (
		<div>
			<Drawer anchor="right" open={open} onClose={props.onClose(false)}>
				{menu()}
			</Drawer>
		</div>
	)
}

export default MobileMenuDrawer;