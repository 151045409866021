import React, {useState} from 'react';
import './about-section.css'
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";

const AboutSection = props => {
	let { translate } = props;

	return (
		<div
			//className={"about-section"}
			style = {{
				margin: 'auto',
				textAlign: 'center',
			}} >
				<br />
			<h1 className={"title"}>{translate("AboutSection.Title")}</h1>
			<h3>{translate("AboutSection.SubTitle")}</h3>

			<p></p>

			<VideoPlayer />

		</div>
	)
}

export default AboutSection;

//

const VideoPlayer = props => {
	let [openModal, setOpenModal] = useState(false);

	let openVideoModalClick = e => {
		setOpenModal(true)
	}

	return (
		// <div>
		// 	<div className = "open-video-image-container">
		// 		<div className={"button"} onClick = {openVideoModalClick} >
		// 			<i className="far fa-play-circle"></i>
		// 			Play Video
		// 		</div>
		// 	</div>

		// 	<Dialog
		// 		open = {openModal}
		// 		onClose = {() => {
		// 			setOpenModal(false)
		// 		}}
		// 		fullWidth={true}
		// 		maxWidth={"md"}
		// 		style = {{
		// 			backgroundColor: 'transparent'
		// 		}}
		// 	>
		// 		<DialogContent
		// 			style ={{
		// 				textAlign : 'center'
		// 			}}
		// 		>
		// 			<iframe width="640" height="360" src="https://www.youtube.com/embed/v4V8AvrDNYg" frameBorder="0"
		// 					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
		// 					allowFullScreen></iframe>
		// 		</DialogContent>

		// 	</Dialog>
		// </div>


		<iframe width="960" height="540" src="https://www.youtube.com/embed/v4V8AvrDNYg" frameBorder="0"
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen></iframe>


	)
}