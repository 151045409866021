import React, {useState} from "react";
import './our-classes-section.css'

const OurClassesSection  = props => {
	let { translate } = props;

	return (
		<div style = {{textAlign : 'center'}}>
			<h1 className={"title"}>{translate("OurClassesSection.Title")}</h1>
			<h3>{translate("OurClassesSection.SubTitle")}</h3>
			<p>האימונים כוללים קיהון, קאטה וקומיטה.<br />
			תוכניות האימון בימי ב' ו-ד' משלבות תכני קיהון, קאטות ותרגילי קומיטה תחרותי, בכל אימון.<br />
			האימונים בימי ו' מתמקדים בקומיטה תחרותי. האימונים בימי שבת מתמקדים בקאטה</p>
			{/* <p>{translate("OurClassesSection.Description")}</p> */}
			{/* <ClassesContainer translate = {translate} /> */}
		</div>
	)
}

export default OurClassesSection;

//

const ClassesContainer = props => {
	let { translate } = props;
	const classes = [
		{
			type : "sport",
			classes : [
				{toRender : <div className={"class-type-container"}>
						<h2 className={"title"}>
							קראטה ספורטיבי
						</h2>

						<p className={"description"}>
							TODO: Add photos of sports Karate and relevant text
						</p>
					</div>}
				// {name : "kids sport"},
				// {name : "kids sport"},
				// {name : "kids sport"},
				// {name : "kids sport"},
				// {name : "kids sport"},
				// {name : "kids sport"},
			]
		},
		{
			type : "traditional",
			classes : [
				{toRender : <div className={"class-type-container"}>
						<h2 className={"title"}>
						קראטה מסורתי
						</h2>

						<p className={"description"}>
							TODO: Add photos of traditional Karate and relevant text
						</p>
					</div>}
				// {name : "kids trad"},
				// {name : "kids trad"},
				// {name : "kids trad"},
				// {name : "kids trad"},
				// {name : "kids trad"},
				// {name : "kids trad"},
			]
		}
	];
	let _initialClass = classes[0];
	const [selectedClass, setSelectedClass] = useState(_initialClass);

	let selectClassClick = c => {
		setSelectedClass(c)
	}

	return (
		<div className={"classes-container"}>
			<div className = "classes-buttons-container" >
				<div
				onClick={() => {selectClassClick(classes[0])}}
					className={"button"} is-selected = {(selectedClass.type === 'sport').toString()} >
					{translate("OurClassesSection.SportButton")}
				</div>

				<div
					onClick={() => {selectClassClick(classes[1])}}
					className={"button"} is-selected = {(selectedClass.type === 'traditional').toString()} >
					{translate("OurClassesSection.TraditionalButton")}
				</div>
			</div>


			<div className = "lasses-type-container">
				{
					selectedClass.classes.map((c, i) => {
						return c.toRender;
						// return <ClassGridItem key = {i} name = {c.name} />
					})
				}
			</div>
		</div>
	)
}

const ClassGridItem = props => {
	let imgURL = 'https://template81566.motopreview.com/mt-demo/81500/81566/mt-content/uploads/2019/05/mt-1821-img06.jpg';
	let { name } = props;


	return (
		<div
			className={"class-grid-item"}
			style = {{
			backgroundImage : `url(${imgURL})`
			}} >

			<div className = "class-name">
				<div>
					{name}
				</div>
			</div>
		</div>
	)
}